import React, { useState } from 'react'
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import YouTube from 'react-youtube';

import intro from './assets/image1.jpg'
import slidetwo from './assets/image2.jpg'
import slidethree from './assets/image3.jpg'
import slidefour from './assets/image4.jpg'
import slidefive from './assets/image5.jpg'
import slidesix from './assets/image6.jpg'
import slideseven from './assets/image7.jpg'
import slideeight from './assets/image8.jpg'
import slidenine from './assets/image9.jpg'
import slideten from './assets/image10.jpg'
import slideeleven from './assets/image11.jpg'
import slidetwelve from './assets/image12.jpg'
import slidethirteen from './assets/image13.jpg'
import slidefourteen from './assets/image14.jpg'
import slidefifteen from './assets/image15.jpg'
import slidesixteen from './assets/image16.jpg'
import slideseventeen from './assets/image17.jpg'
import slideeighteen from './assets/image18.jpg'
import slidenineteen from './assets/image19.jpg'
import slidetwenty from './assets/image20.jpg'
import slidetwentyone from './assets/image21.jpg'
import slidetwentytwo from './assets/image22.jpg'
import slidetwentythree from './assets/image23.jpg'
import slidetwentyfour from './assets/image24.jpg'
import slidetwentyfive from './assets/image25.jpg'
import slidetwentysix from './assets/image26.jpg'
import slidetwentyseven from './assets/image27.jpg'


import Start from './components/Start/Start'
import './App.css'

const Slide = ({ event }) => {
    return (
        <>
            <Zoom scale={1.4} arrows={false} duration={3000} infinite={false}>
                <div className="each-slide-effect">
                    <div className="intro-text">
                        <span>Today is a very special day!</span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="intro-text">
                        <span>I hope you enjoy it. Thank you for all that you do for us.</span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="intro-text">
                        <span>We love you.</span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidefive})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidesix})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slideten})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${intro})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidetwo})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidethree})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slideseventeen})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="intro-text">
                        <span>I remember when our family first started...</span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="intro-text">
                        <span>...the fur family I mean.</span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidetwentythree})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slideeleven})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidetwelve})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidethirteen})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidefourteen})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidefour})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidefifteen})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidesixteen})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="intro-text">
                        <span>then the real fun began.</span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidenine})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slideeighteen})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidetwentytwo})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidetwentyfour})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slideseven})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidetwenty})`
                    }}>
                    </div>
                </div>

                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidetwentyfive})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidetwentysix})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidetwentyseven})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="intro-text">
                        <span>I know our life can be rough sometimes...</span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidetwentyone})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slideeight})`
                    }}>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="intro-text">
                        <span>But there's no one I would rather endure it with than you.</span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="intro-text">
                        <span>And I hope that we can endure it together for the rest of our lives.</span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="intro-text">
                        <span>Happy Birthday, My Love.</span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div className="intro-text">
                        <span>You are my Heaven.</span>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{
                        backgroundImage: `url(${slidenineteen})`,
                        textAlign: 'center'
                    }}>
                        <span style={{ position: 'fixed', left: 0, bottom: 0, width: '100%', fontSize: '30px' }}>The End.</span>
                    </div>
                </div>
            </Zoom>
        </>

    )
}

const App = () => {
    const [step, setStep] = useState(1)
    const [youtubeEvent, setYoutubeEvent] = useState(null)
    const onPlayerReady = (event) => {
        setYoutubeEvent(event)
    }
    const opts = {
        height: '1',
        width: '1',
        playerVars: {
            start: 2
        }
    }
    return (
        <div style={{ width: '100%', backgroundColor: 'black', color: 'white' }}>
            {step === 1 ?
                <Start setStep={setStep} youtubeEvent={youtubeEvent} />
                : step === 2 ?
                    <Slide /> : null
            }
            <YouTube
                videoId='3lSDU48Pr_Q'
                opts={opts}
                onReady={onPlayerReady}
            />
        </div>
    )
}

export default App