import React from 'react'

const Start = ({ setStep, youtubeEvent }) => {
  return (
    <div style={{ backgroundColor: '#000000', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
        <div style={{color: 'white', margin: '5px', fontSize: '48px', fontWeight: 'bold'}}>For Destiny</div>
        <div style={{color: 'white', margin: '5px', fontSize: '16px', fontWeight: 'bold'}}>This video is made for mobile devices only.</div>
        <div style={{color: 'white', margin: '10px'}}>(sound on)</div>
        <button 
          style={{
            width: '90px',
            height: '90px', 
            backgroundColor: '#33FF5E',
            border: '3px solid white',
            borderRadius: '50%', 
            fontWeight: 'bold',
            fontFamily: 'Caveat',
            fontSize: '18px',
            color: 'black', 
            textAlign: 'center'
          }}
          onClick={() => {
          setStep(2);
          youtubeEvent.target.playVideo()
        }}>Start</button>
    </div>
  )
}

export default Start